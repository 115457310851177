export class DonationJsonList {
    deserialize(input: any) {

        const Obj = {
            churchId: input.churchId,
            memberId: input.memberId,
            personName: input.personName,
            donationTypeId: input.donationTypeId,
            donationDate: input.donationDate,
            paymentTypeId: input.paymentTypeId,
            amount: input.amount,
            transactionNumber: input.transactionNumber,
            notes: input.notes,
            id: input.id,
            donationType: input.donationType,
            paymentType: input.paymentType,
            receiptNumber: input.receiptNumber,
            mobileNumber: input.mobileNumber,
            email: input.email,
            memberEmail:input.memberEmail,
            memberContactNumber:input.memberContactNumber
        }
        return Obj;
    }

}