import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn : 'root'
})
export class ExpenseTypeService{

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getAllExpenseType(isactive?: boolean): Observable<any> {
        if (isactive) {
              return this.http.get<any>(this.authService._getAllExpenseType + '?active=' + isactive);
            }
        else {
             return this.http.get<any>(this.authService._getAllExpenseType);
        }
    }

    public getExpenseById(id): Observable<any> {
        return this.http.get<any>(this.authService._getExpenseTypeById + '?id=' + id)
      }

    public saveExpenseType(data) {
        return this.http.post<any>(this.authService._saveExpenseType, data);
    }

    public updateExpenseType(data) {
        return this.http.put(this.authService._updateExpenseType, data)
      }

    public deleteExpenseType(id):Observable<any>
    {
        return this.http.delete<any>(this.authService._deleteExpenseType +'?id=' + id)
    }

}