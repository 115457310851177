import * as moment from 'moment';

export class GroupMemberJsonList {
    deserialize(input: any) {

        const splitData = input.firstName ? input.firstName.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = '';

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }

        const Obj = {
            groupId: input.groupId,
            memberId: input.memberId,
            mobileNumber: input.mobileNumber,
            id: input.id,
            active: input.active,
            salutation: input.displayName ? nameArray : '',
            firstName: input.displayName,
            middleName: input.middleName,
            lastName: input.lastName,
            memberImage: input.memberImage,
        }
        return Obj;
    }
}


export class GroupMemberJSONList {
    deserialize(input: any) {
        const Obj = {
            image: input.memberImage,
            memberId: input.memberId,
            name: input.displayName,
            contactNumber: input.mobileNumber,
        }
        return Obj;
    }
}

export class NonGroupMemberJSONList {
    deserialize(input: any) {
        const Obj = {
            image: input.image,
            memberId: input.memberId,
            name: input.name,
            contactNumber: input.contactNumber
        }
        return Obj;
    }
}


export class TemplateListJsonList {
    deserialize(input: any) {


        const Obj = {
            churchId: input.churchId,
            templateName: input.templateName,
            html: input.html,
            id: input.id,
        }
        return Obj;
    }
}


export class LiscenseDetailsJson {
    deserialize(input: any) {
        const Obj = {
            allowedMemberCount: input.allowedMemberCount,
            allowedSMSCount: input.allowedSMSCount,
            churchType: input.churchType,
            licenseExpiryDateTime: moment.utc(input.licenseExpiryDateTime).local().format('DD-MM-YYYY'),
            licenseStatus: input.licenseStatus,
            remainingMemberCount: input.remainingMemberCount,
            remainingSMSCount: input.remainingSMSCount,
            smsPackageExpiryDateTime: moment.utc(input.smsPackageExpiryDateTime).local().format('DD-MM-YYYY'),
        }
        return Obj;
    }
}
