import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getUserList(): Observable<any> {
    return this.http.get(this.authService._getAllUsers + '?active' );
  }

  public changePassword(data) {
    return this.http.put(this.authService._changePassword, data);
  }

  public resetPassword(data) {
    return this.http.put(this.authService._resetPassword, data);
  }

  public getUserById(id): Observable<any> {
    return this.http.get(this.authService._getUserById + '?id=' + id);
  }

  public updateUser(data) {
    return this.http.put(this.authService._updateUser, data)
  }

  public deleteUser(id): Observable<any> {
    return this.http.delete(this.authService._userDeleteById + '?id=' + id);
  }

  public getMfaCredentials(): Observable<any> {
    return this.http.get(this.authService._getMfaCredentials);
  }

  public getUserProfile(): Observable<any> {
    return this.http.get(this.authService._getUserProfile);
  }

  public validatemfacredentials(data): Observable<any> {
    return this.http.post(this.authService._validatemfacredentials, data);
  }

  public switchMfaStatus(active): Observable<any> {
    return this.http.put(this.authService._switchMfaStatus + "?status=" + active,'');
  }


}
