import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LoginService {
    constructor(private authService: AuthService, private http: HttpClient) { }

    public loginUser(loginData) {
        return this.http.post(this.authService._login, loginData);
    }

    public deleteUserById(deleteId): Observable<any> {
        return this.http.delete(this.authService._userDeleteById + '?id=' + deleteId);
    }

    public deactivateUserById(deactivateId): Observable<any> {
        return this.http.put(this.authService._userdeactivateById + '?id=' + deactivateId, '');
    }

    public ActiveUserById(Id) {
        return this.http.put(this.authService._userActiveById + '?id=' + Id, '');
    }
}
