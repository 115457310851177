import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DonationTypeService {

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getDonationTypes(isactive?: boolean): Observable<any> {
      if (isactive) {
        return this.http.get<any>(this.authService._getAllDonationType + '?active=' + isactive);
      } else {
        return this.http.get<any>(this.authService._getAllDonationType);
      }
    }
  
    public getDonationTypeById(data): Observable<any> {
      return this.http.get<any>(this.authService._getDonationTypeById + '?id=' + data.id);
    }
  
    public addDonationType(data) {
      return this.http.post<any>(this.authService._addDonationType, data);
    }
  
    public updateDonationType(data) {
      return this.http.put(this.authService._updateDonationType, data);
    }
  
    public donationUpdateSequence(data) {
      return this.http.put(this.authService._donationUpdateSequence, data);
    }
  
    public deleteDonationType(deleteId): Observable<any> {
      return this.http.put(this.authService._deleteDonationTypeById + '?id=' + deleteId, '');
    }
  
    public deactivDonationType(deactivateId): Observable<any> {
      return this.http.put(this.authService._deactivateDonationTypeById + '?id=' + deactivateId, '')
    }
  
    public ActiveDonationTypeById(Id) {
      return this.http.put(this.authService._ActiveDonationTypeById + '?id=' + Id, '');
    }

}