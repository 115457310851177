import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public sendMail(data) {
    return this.http.post(this.authService._sendMail, data)
  }

  public uploadFile(data) {
    return this.http.post(this.authService._uploadFile, data)
  }
  public getEmailContent() {
    return this.http.get<any>(this.authService._getEmailContent);
  }
  public getContentById(id): Observable<any> {
    return this.http.get<any>(this.authService._getContentById + '?id=' + id)
  }
  public updateEmailContent(data):Observable<any>{
    return this.http.put(this.authService._updateEmailContent,data);
  }
}