import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn : 'root'
})
export class ExpenseSubTypeService{

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getAllExpenseSubType(isactive?: boolean): Observable<any> {
        if (isactive) {
              return this.http.get<any>(this.authService._getAllExpenseSubType + '?active=' + isactive);
            }
        else {
             return this.http.get<any>(this.authService._getAllExpenseSubType);
        }
    }

    public getExpenseSubTypeById(id): Observable<any> {
        return this.http.get<any>(this.authService._getExpenseSubTypeById + '?id=' + id);
      }


    public saveExpenseSubType(data) {
        return this.http.post<any>(this.authService._saveExpenseSubType, data);
    }

    public updateExpenseSubType(data) {
        return this.http.put(this.authService._updateExpenseSubType, data)
      }

      public deleteExpenseSubType(id):Observable<any>
      {
          return this.http.delete<any>(this.authService._deleteExpenseSubType +'?id=' + id)
      }

      public getExpenseTypebyid (id):Observable<any>
      {
          return this.http.post(this.authService._getExpenseTypebyExpenseTypeid , id)
      }

}