import * as moment from 'moment';

export class BirthdayMemberListJson {
    deserialize(input: any) {
        let firstName = '';

        const middleName = input.middleName ? input.middleName : '';
        const lastName = input.lastName ? input.lastName : '';

        let splittedFirstName = []; // For splitting Mr.FirstName

        if (input.firstName) {
            firstName = input.firstName;
            splittedFirstName = firstName.split('.');
        }

        let salutation, fullFirstName = '';

        if (splittedFirstName.length > 1) {
            salutation = splittedFirstName[0];
            delete splittedFirstName[0]
            splittedFirstName.forEach((splittedNamePortion, i) => {
                fullFirstName = fullFirstName + splittedNamePortion + ((i !== splittedFirstName.length - 1) ? ' ' : '');
            });
        } else if (splittedFirstName.length > 0 && splittedFirstName.length === 1) {
            fullFirstName = splittedFirstName[0];
        }

        const Obj = {
            salution: salutation,
            fname: fullFirstName,
            familyId: input.familyId,
            churchId: input.churchId,
            memberCoverNumber: input.memberCoverNumber,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            age: input.dateOfBirth ? moment().diff(input.dateOfBirth, 'years') : "",
            name: salutation ? (salutation + '. ' + fullFirstName + ' ' + middleName + ' ' + lastName)
                : (fullFirstName + ' ' + middleName + ' ' + lastName),
            withSaluation: fullFirstName + ' ' + middleName + ' ' + lastName,
            gender: input.gender,
            dateOfBirth: input.dateOfBirth,
            deathDate: input.deathDate,
            yearOfDeathRemembrance: input.deathDate ? moment().diff(input.deathDate, 'years') : "",
            maritalStatus: input.maritalStatus,
            dateOfMarried: input.dateOfMarried,
            relationshipId: input.relationshipId,
            baptised: input.baptised,
            confirmed: input.confirmed, 
            occupationId: input.occupationId,
            familyHead: input.familyHead,
            contactNumber:input.contactCode && input.contactNumber ? input.contactCode +'-' + input.contactNumber:input.contactNumber ,
            contactCode: input.contactCode,
            email: input.email,
            memberImage: input.memberImage ? input.memberImage : 'assets/default_user/usericon.png',
            interest: input.interest,
            ministerialInterest: input.ministerialInterest,
            id: input.id,
            formNumber: input.formNumber ? input.formNumber : '',
            active: input.active,
            addedOn: input.addDateTime
        }
        return Obj;
    }
}

export class PrayerRequestListJson {
    deserialize(input: any) {
        const Obj = {
            prayerPoint: input.prayerPoint,
            attachmentURL: input.attachmentURL,
            requestToPastor: input.requestToPastor,
            homePrayerRequest:input.homePrayerRequest,
            memberName: input.memberName,
            memberImage: input.memberImage,
            isExpandElement: false,
            id: input.id
        }
        return Obj;
    }
}

