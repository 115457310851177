import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllDonation(data) {
    return this.http.post<any>(this.authService._getAllDonation, data);
  }

  public getDonationById(id): Observable<any> {
    return this.http.get<any>(this.authService._getDonationById + '?id=' + id)
  }

  public saveDonation(data) {
    return this.http.post(this.authService._saveDonation, data);
  }

  public updateDonation(data) {
    return this.http.put(this.authService._updateDonation, data)
  }

  public deleteDonation(Id): Observable<any> {
    return this.http.put(this.authService._deleteDonation + '?id=' + Id, '')
  }

  public deactivateDonation(Id): Observable<any> {
    return this.http.put(this.authService.deactivateDonation + '?id=' + Id, '')
  }

}