import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HarvestFundService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAuctionByMember(data) {
    return this.http.post<any>(this.authService._AuctionByMember, data);
  }

  public getAuctionDetail(id) {
    return this.http.post<any>(this.authService._getAuctionDetail + '?auctionId=' + id, '');
  }

  public getAllAuctionItem(data) {
    return this.http.post<any>(this.authService._getAllAuctionItem, data);
  }

  public getAllOutstandingDetails(data) {
    return this.http.post<any>(this.authService._getAllOutstandingDetails, data);
  }

  public addAcutionItem(data) {
    return this.http.post<any>(this.authService._addAcutionItem, data);
  }
  public saveAuction(data) {
    return this.http.post(this.authService._saveAuction, data)
}
  public addAuctionPayment(data) {
    return this.http.post<any>(this.authService._addAcutionPayment, data);
  }

  public importHarvestFestival(data) {
    return this.http.post(this.authService._importHarvestFestival, data);
  }
  public getAuctionImportExe(active): Observable<any>{
    return this.http.get<any>(this.authService._allExeFile + '?active=' + active);
} 


}
